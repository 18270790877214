import LayerGroup from 'ol/layer/Group.js'
import TileWMS from 'ol/source/TileWMS.js'
import TileLayer from 'ol/layer/Tile.js'

/* 
TODO:
see:
https://osm-hr.org/2021/07/30/ortofoto-2020-i-mapproxy-hr/
https://github.com/osm-hr/mapproxy-hr
https://geoportal.dgu.hr/#/menu/moji-detalji
*/ 
export const cadastre = new LayerGroup(
    {
        name: 'DGU KP',
       
        visible:false,
        layers: [
            new TileLayer({
                visible:false,
                source: new TileWMS({
                    url: 'https://geoportal.dgu.hr/services/inspire/orthophoto_2019_2020/ows',
                    params: {
                        'FORMAT': 'image/png',
                        'TRANSPARENT': 'TRUE',
                        'VERSION': '1.3.0',
                        'SERVICE': 'WMS',
                        'REQUEST': 'GetMap',
                        'LAYERS': 'OI.OrthoimageCoverage',
                        'STYLES': '',
                    },
                    serverType: 'geoserver', 
                    //crossOrigin: 'anonymous', // Important for CORS, if needed
                })
            }),
           /*  new TileLayer({
                name: 'DGU DOF',
                baseLayer: true,
                source: new TileWMS({
                    url:
                        'https://gis.edc.hr/geoserver/dofWMS/ows?SERVICE=WMS',
                    params: { LAYERS: 'dofWMS:DGU_DOF_2021_2022', TILED: true },
                    crossOrigin: 'anonymous'
                })
            }), */
            new TileLayer({
                visible:false,
                
                minZoom: 14,
                source: new TileWMS({
                    url:
                        'https://api.uredjenazemlja.hr/services/inspire/cp_wms/ows?',
                    params: { 'layers': 'cp:CP.CadastralZoning' },
                    crossOrigin: 'anonymous',
                }),
            }),
            new TileLayer({
               
                visible:false,
                minZoom: 14,
                source: new TileWMS({
                    url:
                        'https://api.uredjenazemlja.hr/services/inspire/cp_wms/ows?',
                    params: { 'layers': 'cp:CP.CadastralParcel' },
                    crossOrigin: 'anonymous'
                })
            }),
           
        ]
    }
)