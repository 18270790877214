import Control from 'ol/control/Control'
import Source from 'ol/source/Vector'
import Layer from 'ol/layer/Vector'
import { Style, Stroke, Text, Fill } from 'ol/style.js'
import GeoJSON from 'ol/format/GeoJSON'
import locale from '../lang/hr.json'
import { IMAGIS } from '../index.js';
import { Tooltip } from 'bootstrap'
import { register } from 'ol/proj/proj4.js'
import proj4 from 'proj4'
import Projection from 'ol/proj/Projection'

// !! TODO: update with new cadastre req options

export class Cadastre extends Control {
  constructor(options = {}) {
    const element = document.createElement('div');
    element.className = 'accordion-item';
    element.innerHTML = `
          <h2 class="accordion-header">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#cadastre-accordion-item">
            <i class="fa-regular fa-copy fs-xs" style="margin-right: 5px;"></i>  
            ${locale.cadastreTitle}
            </button>
          </h2>
          <div id="cadastre-accordion-item" class="accordion-collapse collapse ${options.show ? 'show' : ''}" data-bs-parent="#index-accordion">
            <div class="accordion-body px-1">
            </div>
          </div>
      `;
    super({
      target: options.target,
      element
    })
    this.container = element.querySelector('.accordion-body')
    this.layerName = 'kc'
    this.state = {
      puk: 'Split',  // read from api.properties.cadastre
      okn: 'Omiš',   // read from api.properties.cadastre
      ko: 'Omiš',   // read from api.properties.cadastre
      tmpLayer: options.tmpLayer || true,
      label: options.label || true,
      timeout: options.timeout || 3000, //for fetch
      maxRetries: options.maxRetries || 3, //for fetch
      url: options.url || 'oss',// oss || api (api returns incorrect parcel positions), //for getUrl()
      color: options.color || 'red', // parcel border color
      colorText: options.colorText || 'black', // parcel annotation color
    }
    this.element.addEventListener('shown.bs.collapse', () => {


    });
    this.layerName = 'kc'
    this.element.addEventListener('hidden.bs.collapse', () => {

      this.layer.getSource().clear()
      this.container.querySelector('.kc').value = ''

    });
  }
  setMap(map) {
    super.setMap(map)
    this.map = map
    IMAGIS.api.getProperties()
      .then(r => {
        this.state = { ...this.state, ...r.cadastre }
        IMAGIS.api.getCadastre().then(r => {
          this.state.kl = r
          this.renderHTML()
        })
      })
    this.layer = this.generateLayer()
    if (this.state.tmpLayer) this.layer.setMap(map)
    else console.log('Add geojson source & geoStyle to server data.')
  }

  handlePuk(evt) {
    console.log(evt.target.value)
    this.state.puk = evt.target.value
    this.state.okn = undefined
    this.state.ko = undefined
    this.state.kc = undefined
    this.renderHTML()
  }
  handleOkn(evt) {
    this.state.okn = evt.target.value
    this.state.ko = undefined
    this.state.kc = undefined
    this.renderHTML()
  }
  handleKo(evt) {
    this.state.ko = evt.target.value
    this.renderHTML()
  }
  handleKc() {
    const kc = this.container.querySelector('.kc')
    this.state.kc = kc.value
    this.state.mb = this.state.kl.find((x) => x.puk === this.state.puk &&
      x.okn === this.state.okn && x.ko === this.state.ko).mbr
    this.layer.getSource().clear()
    const dataForm = this.container.querySelector('.data')
    dataForm.classList.add('d-none')
    if (!kc.value) {
      kc.classList.remove('is-invalid')
      kc.classList.add('is-valid')
    }
    this.fetchWithDynamicRetry(this.getUrl())
      .then((data) => {
        if (data.totalFeatures === 0) {
          kc.classList.remove('is-valid')
          kc.classList.add('is-invalid')
        } else {
          kc.classList.remove('is-invalid')
          kc.classList.add('is-valid')
          this.drawParcel(data)
          // there is only one parcel per req...
          this.state.parcelId = data.features[0].properties.CESTICA_ID
          this.getParcelIdData(data)
        }
      })
  }
  getParcelIdData() {
    fetch(`/inf?parcelId=${this.state.parcelId}`)
      .then(r => {
        r.json()
          .then(r => {
            this.state.parcelData = r
            this.renderHTML()
          })
      })
  }
  handleClear() {
    this.state.kc = undefined
    this.state.parcelData = undefined
    this.renderHTML()
  }
  handleResend() {
    const l = this.map.getAllLayers().filter(x => ['dof', 'cp', 'cz'].includes(x.get('name')))
    l.forEach(x => x.getSource().refresh())
    this.handleKc()
  }
  attachEventListeners() {
    this.container.querySelector('.puk').addEventListener('change', evt => this.handlePuk(evt))
    this.container.querySelector('.okn').addEventListener('change', evt => this.handleOkn(evt))
    this.container.querySelector('.ko').addEventListener('change', evt => this.handleKo(evt))
    this.container.querySelector('.kc').addEventListener('change', evt => this.handleKc())
    this.container.querySelector('.clear').addEventListener('click', () => this.handleClear())
    this.container.querySelector('.resend').addEventListener('click', () => this.handleResend())
  }
  renderHTML() {
    this.container.replaceChildren(this.generateHTML())
    this.attachEventListeners()

  }
  generateHTML() {
    const puk = this.elt('div', { className: 'mb-3' },
      this.elt('div', { className: 'form-label' }, locale.cadastreOffice),
      this.elt('select', { className: 'puk form-select form-select-sm' })
    )
    const pukList = [...new Set(this.state.kl.map((x) => x.puk))]
    if (!this.state.puk) this.state.puk = pukList[0]
    pukList.forEach(x => puk.children[1].add(new Option(x, x, undefined, x === this.state.puk)))
    const okn = this.elt('div', { className: 'mb-3' },
      this.elt('div', { className: 'form-label' }, locale.cadastreSector),
      this.elt('select', { className: 'okn form-select form-select-sm' })
    )
    const oknList = [...new Set(this.state.kl.filter((x) => x.puk === this.state.puk).map((x) => x.okn))]
    if (!this.state.okn) this.state.okn = oknList[0]
    oknList.forEach(x => okn.children[1].add(new Option(x, x, undefined, x === this.state.okn)))
    const ko = this.elt('div', { className: 'mb-3' },
      this.elt('div', { className: 'form-label' }, locale.cadastreCadMunicipalityRegNumName),
      this.elt('select', { className: 'ko form-select form-select-sm' })
    )
    const koList = [...new Set(
      this.state.kl.filter((x) => x.puk === this.state.puk && x.okn === this.state.okn).map((x) => x.ko))]
    if (!this.state.ko) this.state.ko = koList[0]
    koList.forEach(x => ko.children[1].add(new Option(x, x, undefined, x === this.state.ko)))
    const placeholder = locale.cadastreParcelNo
    const kc = this.elt('div', { className: 'input-group has-validation mb-3' },
      this.elt('input', {
        type: 'text', className: 'kc form-control form-control-sm',
        placeholder,
        autofocus: true
      }),
      this.elt('button', { className: 'clear btn btn-outline-secondary' },
        this.elt('i', { className: 'fa-regular fa-xmark' })),
      this.elt('button', { className: 'resend btn btn-outline-secondary' },
        this.elt('i', { className: 'fa-regular fa-repeat' })),
      this.elt('div', { className: 'invalid-feedback' }, locale.cadastreNoParcel)
    )
    if (this.state.kc) kc.firstChild.value = this.state.kc
    else kc.firstChild.placeholder = placeholder


    const dataTabs =
      this.elt('div', { className: 'nav nav-tabs' },
        this.elt('button', { className: 'nav-link active', type: 'button' }, locale.cadastreBasic),
        this.elt('button', { className: 'nav-link', type: 'button' }, locale.cadastreUse),
        this.elt('button', { className: 'nav-link', type: 'button' }, locale.cadastreOwner)
      )
    Array.from(dataTabs.children).forEach((x, i) => {
      x.dataset.bsToggle = 'tab'
      x.dataset.bsTarget = i === 0 ? '#cadastre-data-base' : i === 1 ? '#cadastre-data-use' : '#cadastre-data-owner'
    })
    const dataContent = this.elt('div', { className: 'tab-content' },
      this.elt('div', { className: 'tab-pane fade show active', id: 'cadastre-data-base' }),
      this.elt('div', { className: 'tab-pane fade ', id: 'cadastre-data-use' }),
      this.elt('div', { className: 'tab-pane fade', id: 'cadastre-data-owner' })
    )
    const data = this.elt('div', { className: `data ${this.state.parcelData ? '' : 'd-none'} position-relative` },
      dataTabs, dataContent)
    const btn = this.elt('button', {
      className: 'btn btn-link btn-sm border-0 top-0 end-0 position-absolute',
      title: locale.cadastreCopyToClipboard
    }, this.elt('i', { className: 'fa-regular fa-copy fs-xs' }))
    btn.dataset.bsToggle = 'tooltip'
    const ttip = new Tooltip(btn)

    btn.addEventListener('click', () => {
      const text = [`${locale['cadastreParcelNumber']};${p.parcelNumber || ''}`,
      `${locale['cadastreCadMunicipalityRegNumName']};${p.cadMunicipalityRegNum || ''} ${p.cadMunicipalityName || ''}`,
      `${locale['cadastreArea']};${p.area || ''}`,
      `${locale['cadastrePossessionSheetNumber']};${p.parcelParts[0].possessionSheetNumber || ''}`,
      `${locale['cadastrePossessionSheetCount']};${p.parcelParts.length}`,
      ]
      text.push('* ' + locale.cadastreUse)
      text.push(`${locale.cadastreParcelNoame};${locale.cadastreArea}`)
      p.parcelParts.forEach(x => {
        text.push(`${x.name || ''};${x.area || ''}`)
      })
      text.push('* ' + locale.cadastreOwner || '')
      text.push(`${locale.cadastreName};${locale.cadastreOwnership};${locale.cadastreAddress}`)
      p.possessionSheets[0].possessors.forEach(x => {
        text.push(`${x.name || ''};${x.ownership || ''};${x.address || ''}`)
      })
      navigator.clipboard.writeText(text.join('\n'))
      ttip.hide()
    })
    data.append(btn)
    const p = this.state.parcelData
    console.log(p)
    if (p) {
      const basicItems = [{
        name: locale['cadastreParcelNumber'],
        value: p.parcelNumber || ''
      },
      {
        name: locale['cadastreCadMunicipalityRegNumName'],
        value: `${p.cadMunicipalityRegNum || ''} ${p.cadMunicipalityName || ''}`
      },
      {
        name: locale['cadastreArea'],
        value: p.area || ''
      },
      {
        name: locale['cadastrePossessionSheetCount'],
        value: p.parcelParts.length
      }]
      basicItems.forEach(x => {
        dataContent.querySelector('#cadastre-data-base').append(
          this.elt('div', { className: 'row' },
            this.elt('div', { className: 'col' }, x.name || ''),
            this.elt('div', { className: 'col text-end' }, x.value.toString()))
        )
      })
      // add possessionSheetNumber as link
      const sheetNumName = p.isHarmonized ? locale['cadastreOwnerSheetNumber'] : locale['cadastrePossessionSheetNumber']
      const urlOssServices = 'https://oss.uredjenazemlja.hr/public-services/'
      dataContent.querySelector('#cadastre-data-base').append(
        this.elt('div', { className: 'row' },
          this.elt('div', { className: 'col' }, sheetNumName || ''),
          this.elt('div', { className: 'col text-end' },
            this.elt('a', {
              target: '_blank',
              href: encodeURI(`${urlOssServices}search-cad-parcel?cadMunicipalityId=${p.cadMunicipalityId}&possessionSheetNumber=${p.parcelParts[0].possessionSheetNumber}`)
            }, p.parcelParts[0].possessionSheetNumber
            )))
      )
      // add cadastreLrUnitNumber as link
      console.log('Harmonized', p.isHarmonized)
      // if harmonized vlasnički list
      const lrUnits = p.lrUnitsFromParcelLinks
      if (lrUnits) {

        dataContent.querySelector('#cadastre-data-base').append(
          this.elt('div', { className: 'row' },
            this.elt('div', { className: 'col' }, locale['cadastreLrUnitNumber'] || ''),
            this.elt('div', { className: 'col text-end' },
              this.elt('a', {
                target: '_blank',
                href: encodeURI(`${urlOssServices}review-lr-bdc?mainBookId=${lrUnits[0].mainBookId}&lrUnitNumber=${lrUnits[0].lrUnitNumber}`)
              }, lrUnits[0].lrUnitNumber
              )))
        )
      }
      dataContent.querySelector('#cadastre-data-use').append(
        this.elt('div', { className: 'row' },
          this.elt('div', { className: 'col fw-bold' }, locale.cadastreName),
          this.elt('div', { className: 'col text-end  fw-bold' }, locale.cadastreArea))
      )
      p.parcelParts.forEach(x => {
        dataContent.querySelector('#cadastre-data-use').append(
          this.elt('div', { className: 'row' },
            this.elt('div', { className: 'col ' }, x.name),
            this.elt('div', { className: 'col text-end' }, x.area.toString()))
        )
      })
      dataContent.querySelector('#cadastre-data-owner').append(
        this.elt('div', { className: 'row' },
          this.elt('div', { className: 'col fw-bold' }, locale.cadastreName),
          this.elt('div', { className: 'col fw-bold' }, locale.cadastreOwnership),
          this.elt('div', { className: 'col text-end  fw-bold' }, locale.cadastreAddress)
        ))
      p.possessionSheets[0].possessors.forEach(x => {
        dataContent.querySelector('#cadastre-data-owner').append(
          this.elt('div', { className: 'row' },
            this.elt('div', { className: 'col' }, x.name || ''),
            this.elt('div', { className: 'col' }, x.ownership || ''),
            this.elt('div', { className: 'col text-end' }, x.address || '')
          )
        )
      })
    }
    return this.elt('div', { className: 'selectors mb-3' }, puk, okn, ko, kc, data)
  }
  drawParcel(r) {
    const features = new GeoJSON().readFeatures(r, {
      dataProjection: this.get3765(),
      featureProjection: this.getMap().getView().getProjection()
    })
    const source = this.layer.getSource()
    source.addFeatures(features)
    features.map((x) => x.set('PUK', this.state.puk))
    features.map((x) => x.set('OKN', this.state.okn))
    features.map((x) => x.set('ko', this.state.ko))
    features.map((x) => x.set('kc', this.state.kc))
    this.getMap()
      .getView()
      .fit(source.getExtent(), this.getMap().getSize())
  }
  getUrl() {
    let url
    if (this.state.url === 'api') {
      url = new URL('https://api.uredjenazemlja.hr/services/inspire/cp/wfs')
      url.search = new URLSearchParams([
        ['service', 'WFS'],
        ['version', '2.0.0'],
        ['request', 'GetFeature'],
        ['PROPERTY', 'label'],
        ['TYPENAMES', 'cp:CadastralParcel'],
        ['outputFormat', 'json'],
        ['cql_filter', `nationalCadastralReference='${this.state.mb}-${this.state.kc}'`],
        ['srsName', this.get3765().getCode()]
      ])
    }
    if (this.state.url === 'oss') {
      url = new URL('https://oss.uredjenazemlja.hr/OssWebServices/wfs')
      url.search = new URLSearchParams([
        ['service', 'WFS'],
        ['version', '1.0.0'],
        ['request', 'GetFeature'],
        ['maxFeatures', '50'],
        ['typeName', 'DKP_CESTICE'],
        ['outputFormat', 'application/json'],
        ['cql_filter', `MATICNI_BROJ_KO=${this.state.mb} AND BROJ_CESTICE='${this.state.kc}'`],
        ['srsName', this.get3765().getCode()],
        ['token', '7effb6395af73ee111123d3d1317471357a1f012d4df977d3ab05ebdc184a46e']
      ])
    }
    return url
  }
  fetchWithDynamicRetry(url) {
    let retries = 0;
    const fetchData = () => {
      const abortController = new AbortController();
      const timeoutId = setTimeout(() => abortController.abort(), this.state.timeout)
      return fetch(url, { signal: abortController.signal })
        .then((response) => {
          clearTimeout(timeoutId)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }
          return response.json()
        })
        .catch((error) => {
          clearTimeout(timeoutId)
          retries++
          /* this.state.infoText = `... pokušaj broj ${retries}`
          this.renderHTML() */
          if (retries < this.state.maxRetries) {
            return fetchData(); // Retry
          } else {
            /*  this.state.infoText = `Pokušati nešto kasnije`
             this.renderHTML() */
          }
        })
    }
    return fetchData()
  }
  generateLayer() {
    const stroke = new Stroke({
      color: this.state.color,
      width: 3
    })
    const text = new Text({
      fill: new Fill({
        color: this.state.colorText
      }),
      stroke: new Stroke({
        color: 'white',
        width: 1
      }),
      font: '16px sans-serif',
      overflow: true
    })
    const style = (feature) => {
      const textContent = [
        { prefix: locale.cadastreOKN, prop: 'OKN', postfix: '' },
        { prefix: locale.cadastreKc, prop: 'kc', postfix: '' },
        { prefix: locale.cadastreKo, prop: 'ko', postfix: '' }
      ].map((x) => {
        if (feature.get(x.prop)) {
          return x.prefix + feature.get(x.prop) + x.postfix
        }
      }).join('\n')
      text.setText(textContent)
      const styleOptons = { stroke }
      if (this.state.label) styleOptons.text = text
      return new Style(styleOptons)
    }
    const layer = new Layer({
      source: new Source(),
      style
    })
    return layer
  }

  elt(type, props, ...children) {
    const dom = document.createElement(type)
    if (props) Object.assign(dom, props)
    for (const child of children) {
      if (typeof child !== 'string') dom.appendChild(child)
      else dom.appendChild(document.createTextNode(child))
    }
    return dom
  }
  get3765() {
    proj4.defs('EPSG:3765',
      '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    register(proj4)
    return new Projection({
      code: 'EPSG:3765',
      units: 'm',
      extent: [208311.05, 4614890.75, 724721.78, 5159767.36]
    })
  }
}