import Control from 'ol/control/Control';
import OLGeolocation from 'ol/Geolocation.js';
import { IMAGIS } from '../index.js';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Fill, Stroke, Circle as CircleStyle } from 'ol/style';

export class Geolocation extends Control {
  constructor(options = {}) {
    const element = document.createElement('div');
    element.className = 'ol-geolocation ol-control ol-unselectable';
    element.innerHTML = `
      <button type="button" name="geolocation-toggle-trace" class="ol-active">
        <i class="fa-regular fa-location-crosshairs"></i>
      </button>
    `;
    super({
      target: options.target,
      element
    });
    // options
    this.zoom = options.zoom || 18;
    this.zoomOnChange = options.zoomOnChange

    this.tracking = false; // To track whether tracing is active
    this.element.addEventListener('click', () => {
      this.toggleTracking();
    });
    this.icon = this.element.querySelector('i')
  }

  setMap(map) {
    super.setMap(map);
    this.geolocation = new OLGeolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: map.getView().getProjection(),
    });
    this.map = map;

    // Create a vector source and layer to hold the traced positions
    this.vectorSource = new VectorSource();
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
      style: new Style({
        image: new CircleStyle({
          radius: 6, // Circle radius
          fill: new Fill({
            color: 'rgba(0, 153, 255, 0.4)', // Circle fill color
          }),
          stroke: new Stroke({
            color: 'rgba(0, 153, 255, 1)', // Circle border color
            width: 2,
          }),
        }),
      }),
    });
    this.map.addLayer(this.vectorLayer); // Add the vector layer to the map
  }

  toggleTracking() {
    if (this.tracking) {
      this.stopTracking(); // Stop if currently tracking
      this.icon.classList.remove('fa-beat')
      console.log('end')
    } else {
      this.startTracking(); // Start tracking
      this.icon.classList.add('fa-beat')
      console.log('start')
    }
  }

  startTracking() {
    this.tracking = true;
    this.geolocation.setTracking(true);

    this.geolocation.on('change', () => {

      const position = this.geolocation.getPosition();
      const heading = this.geolocation.getHeading() || 0;

      // Add the current position as a feature to the vector source
      const locationFeature = new Feature({
        geometry: new Point(position),
      });
      this.vectorSource.addFeature(locationFeature);

      // Center the map on the user's position
      this.map.getView().setCenter(position);
      this.map.getView().setRotation(-heading); // Rotate map based on heading
      // zoom only first time, later just move view center to position
      if (!this.zoomOnChange && this.vectorLayer.getSource().getFeatures().length === 1) {
        this.map.getView().setZoom(this.zoom);
      }
      // stop icon from beating
      this.icon.classList.remove('fa-beat')
    });
  }

  stopTracking() {
    this.tracking = false;
    this.geolocation.setTracking(false); // Stop the geolocation tracking
    this.vectorSource.clear(); // Optionally clear the traced points
  }

  mobilePosition(position) {
    const isMobile = window.innerWidth <= 768;
    const isOffcanvasActive = IMAGIS.offcanvasElement.classList.contains('show');
    if (isMobile && isOffcanvasActive) {
      const offcanvasHeight = IMAGIS.offcanvasElement.offsetHeight;
      const pixelCenter = this.map.getPixelFromCoordinate(position);
      pixelCenter[1] -= offcanvasHeight / 2; // Move up for half of offcanvas height
      return this.map.getCoordinateFromPixel(pixelCenter);
    }
    return position;
  }
}
