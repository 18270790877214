import "./index.css"
import 'bootstrap'
import locale from './lang/hr.json'
import { Api } from "./controls/api"
import { Authorization } from "./controls/auth"
import Map from 'ol/Map.js'
import View from 'ol/View.js'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector.js'
import VectorLayer from 'ol/layer/Vector.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import OpenLayersParser from 'geostyler-openlayers-parser'
import { Zoom, Attribution, Rotate } from 'ol/control'
import Permalink from 'ol-ext/control/Permalink.js'
import { Geolocation } from './controls/geolocation.js'
import { LayerSwitcher } from "./controls/layerSwitcher.js"
import Control from 'ol/control/Control.js'
import OSM from 'ol/source/OSM.js'
import LayerGroup from 'ol/layer/Group.js'
import Tile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS.js'
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import { cadastre } from './controls/geoportal.js';
import { SdfConverter } from './controls/sdfConverter.js'
import { MetersCmms } from "./controls/metersCmms.js"
import { Search } from "./controls/search.js"
import { Telemetry } from "./controls/telemetry.js"
import { Cadastre } from "./controls/cadastre.js"
import { Properties } from "./controls/properties.js"


// Initialize the global object
export const IMAGIS = {
  api: null,
  map: null,
};

let wakeLock = null;

/**
 * Main
 * 
 */
addEventListener("load", () => main());
addEventListener("beforeunload", () => { if (wakeLock !== null) wakeLock.release() });

function main() {
  const auth = new Authorization();
  auth.login((keycloak) => {
    IMAGIS.api = new Api(keycloak);
    // Load initial parameters after successful login
    loadInitialParams((properties) => {
      if (properties) {
        initMap(properties, (failedLayers) => { // Initialize the map with the given properties
          generateUI()  // Call after the map is initialized
          controls()


          navigator.wakeLock.request('screen')
        });
      } else {
        console.error("Failed to load initial parameters.");
      }
    });
  });
}

function controls() {
  const controlNames = IMAGIS.properties.controls
  const top = IMAGIS.map.getTarget().querySelector('.offcanvas-top')
  const accordion = IMAGIS.map.getTarget().querySelector('#index-accordion')
  const bottom = IMAGIS.map.getTarget().querySelector('.offcanvas-bottom')
  IMAGIS.map.addControl(new Search({ target: top }))
  const controls = []
  controlNames.forEach(control => {
    if (control === 'cadastre') controls.push(new Cadastre({ target: accordion }))
    if (control === 'telemetry') controls.push(new Telemetry({ target: accordion }))
    if (control === 'metersCmms') controls.push(new MetersCmms({ target: accordion }))
    if (control === 'properties') controls.push(new Properties({ target: accordion }))
  })
  if (controls.length > 0) {
    // Add the 'show' class to the first control's accordion collapse
    controls[0].element.querySelector('.accordion-collapse').classList.add('show');
  }
  controls.forEach(x => IMAGIS.map.addControl(x))
}

function generateUI() {
  generateAuth()
  const element = document.createElement('div');
  element.className = 'btn-group m-2 float-end';
  const menu = new Control({ element })
  IMAGIS.map.addControl(menu);
  generateOffCanvas(menu.element)
}

// generate offcanvas with accordion 
function generateOffCanvas(target) {
  // to trigger offcanvasElement
  const element = document.createElement('div');
  element.className = 'btn btn-primary active';
  element.innerHTML = `<i class="fa-regular fa-bars"></i>`;
  IMAGIS.map.addControl(new Control({ element, target }));
  // offcanvas + accordion
  const offcanvasElement = document.createElement('div');
  offcanvasElement.className = 'offcanvas show'
  offcanvasElement.style.zIndex = '1200'
  offcanvasElement.id = 'index-offcanvas'
  offcanvasElement.tabIndex = '-1'
  /* removed header
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">offcanvasElement</h5>
  </div>
  */
  offcanvasElement.innerHTML = `
  <div class="offcanvas-body p-2">
  <div class="offcanvas-top mb-3"></div>
   <div class="accordion" id="index-accordion"></div>
   <div class="offcanvas-bottom mt-3"></div>
   </div>`
  IMAGIS.map.addControl(new Control({ element: offcanvasElement }));
  element.addEventListener('click', () => {
    element.classList.toggle('active')
    offcanvasElement.classList.toggle('show')
  })
  IMAGIS.offcanvasElement = offcanvasElement
}

// generate authentication button and associated controls
function generateAuth() {

  const sdfConverter = new SdfConverter();
  [sdfConverter].forEach(control => IMAGIS.map.addControl(control));
  // Ensure IMAGIS.api and keycloak are defined
  if (!IMAGIS.api || !IMAGIS.api.keycloak || !IMAGIS.api.keycloak.idTokenParsed) {
    console.error('Keycloak authentication information is not available.');
    return;
  }
  const user = IMAGIS.api.keycloak.idTokenParsed.preferred_username;
  // Create dropdown menu for authentication actions
  const justForDev = `<li>
              <button type="button" class="dropdown-item btn btn-link" name="index-adjust-button">
                  <i class="fa-regular fa-wrench-simple"></i>
                  <span class="mx-3">${locale.adjust}</span>
              </button>
          </li>`
  const element = document.createElement('div');
  element.className = 'btn-group m-2 float-end';
  element.innerHTML = `
    <div class="dropdown">
      <button type="button" class="btn btn-success rounded-circle btn-sm dropdown-toggle" data-bs-toggle="dropdown"
          id="index-auth" style="width: 35px; height: 35px;">
          <span>${user.slice(0, 2).toUpperCase()}</span>
      </button>
      <ul class="dropdown-menu" style="white-space: nowrap">
          <li>
              <span class="dropdown-item-text"><i class="fa-regular fa-user"></i>
                  <span class="mx-3">${user}</span></span>
          </li>
          <li>
              <button type="button" class="dropdown-item btn btn-link" name="index-sdf-button">
                  <i class="fa-regular fa-file-import"></i>
                  <span class="mx-3">${locale.SdfConverterMenuText}</span>
              </button>
          </li>
          <li>
              <button type="button" class="dropdown-item btn btn-link" name="index-permalink-button">
                  <i class="fa-regular fa-link"></i>
                  <span class="mx-3">${locale.link}</span>
              </button>
          </li>
          <li>
              <button type="button" class="dropdown-item btn btn-link" name="index-logoff-button">
                  <i class="fa-regular fa-arrow-right-from-bracket"></i>
                  <span class="mx-3">${locale.logoff}</span>
              </button>
          </li>
      </ul>
    </div>`;

  // Add event listeners for dropdown actions
  element.querySelector('[name=index-logoff-button]').addEventListener('click', () => {
    window.history.pushState(null, null, window.location.pathname);
    IMAGIS.api.keycloak.logout();
  });

  /*  element.querySelector('[name=index-adjust-button]').addEventListener('click', () => {
     configEditor.toggle();
   }); */

  element.querySelector('[name=index-permalink-button]').addEventListener('click', () => {
    const permalink = IMAGIS.map.getControls().getArray().find(x => x instanceof Permalink)
    permalink.setUrlReplace(!permalink.getUrlReplace())
  });

  element.querySelector('[name=index-sdf-button]').addEventListener('click', () => {
    sdfConverter.toggle();
  });

  // Add the dropdown control to the map
  IMAGIS.map.addControl(new Control({ element: element }));
}
// style from geostyle in properties
function geoStylerToOlStyle(geoStylerStyle) {
  const olParser = new OpenLayersParser();
  // Return a promise to convert the GeoStyler style to an OpenLayers style
  return olParser.writeStyle(geoStylerStyle)
    .then(({ output: olStyle }) => olStyle) // Extract the output style from the result
    .catch((error) => {
      console.error('Error converting GeoStyler style to OpenLayers style:', error);
      throw error;  // Propagate the error for the caller to handle
    });
}

export function addGeoJSONLayers(files) {
  const geoJson = new GeoJSON({
    featureProjection: IMAGIS.map.getView().getProjection()
  })
  // Create an array to hold all promises
  const layerPromises = files.map((file, index) => {
    return IMAGIS.api.getGeoJsonData(file.name)
      .then(data => {
        const vectorSource = new VectorSource({
          features: geoJson.readFeatures(data)
        });

        // Convert GeoStyler JSON style to OpenLayers style using OpenLayersParser
        return geoStylerToOlStyle(file.style)
          .then((olStyle) => {
            // Create the VectorLayer with the converted style
            const vectorLayer = new VectorLayer({
              name: file.name,
              source: vectorSource,
              zIndex: index,  // Set zIndex based on the order in the 'files' array
              visible: file.visible !== false,  // Set visibility
              style: olStyle // Apply the converted OpenLayers style
            });
            // Add the layer to the map after it is fully styled
            IMAGIS.map.addLayer(vectorLayer);
            // Resolve the promise once the layer is styled and added
            return { success: true }
          })
          .catch(error => {
            console.warn("Skipping file due to error in style conversion:", file.name)
            return { success: false, error: error, file: file.name }
          });
      })
      .catch(error => {
        console.warn("Skipping file due to error in GeoJSON data loading:", file.name);  // Log minimal error info
        return { success: false, error: error, file: file.name };
      });
  });

  // Return an array of promises
  return layerPromises;
}

function initMap(properties, callback) {
  document.title = properties.title
  const target = document.createElement('div')
  target.className = 'map-target'
  document.body.append(target);
  IMAGIS.map = new Map({
    target: target,
    view: new View({
      center: fromLonLat(properties.center),
      zoom: properties.zoom,
      padding: window.innerWidth <= 768 ? [0, 0, window.innerHeight / 2, 0] : [0, 0, 0, 400]
    }),
    controls: [
      //new Zoom({ zoomInTipLabel: locale.zoomIn, zoomOutTipLabel: locale.zoomOut }),
      new Rotate({ tipLabel: locale.rotate }),
      new Attribution({ collapsed: true, collapsible: true }),
      new Permalink({ visible: false }),
      new Geolocation(),
      new LayerSwitcher({
        iconMap: {
          'OSM': `osm.png`,
          'Bing': `bing.png`,
          'DGU DOF': `dof.png`,
          'DGU KP': `cp.png`,
        }
      })
    ],
    layers: [
      new LayerGroup({
        name: 'tiles',
        layers: [
          new Tile({
            name: 'OSM',
            permalink: 'osm',
            visible: true,
            source: new OSM()
          }),
          new Tile({
            name: 'Bing maps',
            permalink: 'bing',
            visible: false,
            source: new BingMaps({
              placeholderTiles: false,
              key: 'Asy8J51JJ7llyF3k35bhsNUUXZpJ1Sx2XM1AVrVlm1fffX_V-k68gi8VtjOrM68b',
              imagerySet: 'Aerial',
            })
          }),
          new Tile({
            name: 'DGU DOF',
            permalink: 'dof',
            visible: false,
            source: new TileWMS({
              url:
                'https://gis.edc.hr/geoserver/dofWMS/ows?SERVICE=WMS',
              params: { LAYERS: 'dofWMS:DGU_DOF_2021_2022', TILED: true },
              crossOrigin: 'anonymous'
            }),
          }),
          cadastre
        ]
      })
    ]
  })
  IMAGIS.map.getViewport().addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault(); // Stops mobile viewer pinch-to-zoom 
    }
  }, { passive: false });
  // Add layers and wait for all to be loaded and styled
  const layerPromises = addGeoJSONLayers(properties.files)
  // Use Promise.all to detect when all layers are loaded and styled
  Promise.all(layerPromises)
    .then(results => {
      const successfulLayers = results.filter(result => result.success)
      const failedLayers = results.filter(result => !result.success)
      console.log('All layers have been successfully loaded and styled.')
      if (failedLayers.length > 0) {
        console.warn(`${failedLayers.length} layers failed to load or style correctly.`)
      }

      // Perform any additional actions that depend on all layers being processed
      if (typeof callback === 'function') {
        callback(failedLayers);
      }
    })
    .catch(error => {
      console.error('An error occurred during loading or styling layers:', error);
    });
}

function loadInitialParams(callback) {
  IMAGIS.api.getProperties()
    .then(response => {
      IMAGIS.properties = response
      callback(response);
    })
    .catch(error => {
      IMAGIS.properties = null
      console.error('Failed to get project properties:', error);
      callback(null);
    });
}

